import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Logo from "../assets/logo.svg";
import BurgerMenu from "../assets/burger-menu.svg";
import { ContentMargin, ContentPadding } from "./spacing";
import breakpoints from "../variables/breakpoints";

const MenuBar = styled.header`
  background-color: rgb(0 73 75 / 90%);
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 4rem;
  color: #fff;
`;

const MenuRow = styled.nav`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  @media (min-width: ${breakpoints.medium}) {
    justify-content: flex-start;
  }
`;

const StyledLogo = styled(Logo)`
  height: 3rem;
  fill: white;
  flex-shrink: 0;
`;

const StyledBurgerMenu = styled(BurgerMenu)`
  height: 1.25rem;
  fill: white;
`;

const MenuItem = styled(Link)`
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  color: #fff;
  text-decoration: none;
  display: none;

  @media (min-width: ${breakpoints.medium}) {
    display: flex;
    padding: 0 1rem;
  }

  @media (min-width: ${breakpoints.large}) {
    padding: 0 2rem;
  }
`;

const MobileMenuButton = styled.button`
  background: transparent;
  width: 3rem;
  height: 3rem;
  padding: 0;
  border: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: ${breakpoints.medium}) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  position: absolute;
  background-color: white;
  height: calc(100vh - 4rem);
  right: 0;
  top: 4rem;
  z-index: 2;
  transition: transform 0.1s;
  transform: translateX(${({ open }) => (open ? "0%" : "100%")});
  border-left: 1px solid #ddd;

  @media (min-width: ${breakpoints.medium}) {
    display: none;
  }
`;

const MobileMenuItem = styled(Link)`
  display: flex;
  align-items: center;
  color: rgb(0 73 75 / 100%);
  height: 3rem;
  border-bottom: 1px solid #d5d5d5;
  padding: 1rem;
  text-decoration: none;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`

const MobileSiteName = styled(Link)`
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  margin-left: 1rem;

  @media (min-width: ${breakpoints.medium}) {
    display: none;
  }
`

const Menu = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <MenuBar>
      <ContentMargin>
        <ContentPadding>
          <MenuRow>
            <LogoLink to="/"><StyledLogo alt="Laurus-kuoron logo" /></LogoLink>
            <MobileSiteName to="/">Laurus</MobileSiteName>
            <MenuItem to="/">Etusivu</MenuItem>
            <MenuItem to="/konsertit">Konsertit</MenuItem>
            <MenuItem to="/liity-kuoroon">Liity kuoroon</MenuItem>
            <MenuItem to="/kuulumisia">Kuulumisia</MenuItem>
            <MenuItem to="/ota-yhteytta">Ota yhteyttä</MenuItem>
            <MobileMenuButton onClick={toggleMobileMenu}>
              <StyledBurgerMenu />
            </MobileMenuButton>
          </MenuRow>
        </ContentPadding>
      </ContentMargin>
      <MobileMenu open={isMobileMenuOpen}>
        <MobileMenuItem to="/">Etusivu</MobileMenuItem>
        <MobileMenuItem to="/konsertit">Konsertit</MobileMenuItem>
        <MobileMenuItem to="/liity-kuoroon">Liity kuoroon</MobileMenuItem>
        <MobileMenuItem to="/kuulumisia">Kuulumisia</MobileMenuItem>
        <MobileMenuItem to="/ota-yhteytta">Ota yhteyttä</MobileMenuItem>
      </MobileMenu>
    </MenuBar>
  );
};

export default Menu;
