import styled from "styled-components"
import breakpoints from "../variables/breakpoints";

export const ContentMargin = styled.div`
  margin: 0;
  flex-grow: 1;
  @media(min-width: ${breakpoints.medium}) {
    margin: 0 2rem;
  }

  @media(min-width: ${breakpoints.large}) {
    margin: 0 4rem;    
  }
  
`

export const ContentPadding = styled.div`
  padding: 0 2rem;

  @media(min-width: ${breakpoints.medium}) {
    padding: 0 2rem;
  }

  @media(min-width: ${breakpoints.large}) {
    padding: 0 4rem;    
  }


`