import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    box-sizing: border-box;  
    background-color: #f3f2f0;
    color: #232129;
    font-family: 'Source Serif Pro', Georgia, serif;
    line-height: 1.6;
  }

  p {
    line-height: 1.6;
    max-width: 50rem;

    img {
        max-width: 100%;
    }
  }

  .header-picture {
      max-height: 80vh;
      min-height: 40vh;
  }
`

export default GlobalStyle
