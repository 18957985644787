import * as React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Menu from "./menu"
import { ContentMargin, ContentPadding } from "./spacing";
import breakpoints from "../variables/breakpoints";
import GlobalStyle from "./globalStyle"

const MainContent = styled.main`
  background-color: #fff;
  padding: 1rem 0;
`

const PicturePlaceHolder = styled.div`
  height: 4rem;
`

const GatsbyPicture = ({ headerPicture }) => {
    const image = getImage(headerPicture)   
    
    return (
        <GatsbyImage className="header-picture" image={image} alt={headerPicture.description} />
    )
}

const StyledGatsbyPicture = styled(GatsbyPicture)`
    max-height: 80vh;
    min-height: 40vh;
`

const Layout = ({ children, headerPicture }) => {
  return (
    <>
      <GlobalStyle />
      <Menu />
      { headerPicture ? 
            <StyledGatsbyPicture headerPicture={headerPicture} />
        :
            <PicturePlaceHolder />
      }
      <ContentMargin>
        <MainContent>
          <ContentPadding>
            {children}
          </ContentPadding>
        </MainContent>
      </ContentMargin>
    </>
  )
}

export default Layout
